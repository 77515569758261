/* @font-face {
  font-family: 'Helvetica Neue';
  src: url('./fonts/HelveticaNeue-Extended.woff') format('woff');
  font-weight: 400;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('./fonts/Helvetica-Neue-Thin.otf') format('opentype');
  font-weight: 100;
} */

/* @font-face {
  font-family: 'Helvetica Neue';
  src: local('Helvetica Neue');
  font-weight: 400;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: local('HelveticaNeue-Light'), local('Helvetica Neue Light');
  font-weight: 100;
} */

/* @font-face {
  font-family: 'Helvetica Neue';
  src: url('./fonts/HelveticaNeueLight.woff') format('woff');
  font-weight: 400;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: local('HelveticaNeue-Light'), local('Helvetica Neue Light');
  font-weight: 100;
} */

/* @font-face {
  font-family: 'Helvetica Neue';
  src: local('Helvetica Neue');
  font-weight: 400;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: local('HelveticaNeue-Light'), local('Helvetica Neue Light');
  font-weight: 100;
} */

/* @font-face {
  font-family: 'Roboto Custom';
  src: url('./fonts/Roboto-Regular.ttf') format('ttf');
  font-weight: 400;
}

@font-face {
  font-family: 'Roboto Custom';
  src: url('./fonts/Roboto-Thin.ttf') format('ttf');
  font-weight: 100;
} */

/* 
@font-face {
  font-family: 'pinscreen logo';
  src: url('./fonts/pinscreen-logo.eot');
  src: url('./fonts/pinscreen-logo.eot?#iefix') format('embedded-opentype'),
       url('./fonts/pinscreen-logo.woff') format('woff'),
       url('./fonts/pinscreen-logo.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
} */

@font-face {
  font-family: 'Collection';
  src: url('./fonts/Collection-Style1.otf') format('opentype');
  font-weight: 400;
}

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

/* @import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');


@import url('https://fonts.googleapis.com/css2?family=DM+Mono:ital,wght@0,300;0,400;0,500;1,300;1,400;1,500&display=swap'); */

@import url('https://fonts.googleapis.com/css2?family=Gowun+Batang:wght@400;700&display=swap');

html {
  scroll-behavior: auto;
  scrollbar-color: #f5f5f5 #00000094;
}

body {

  font-family: 'Poppins', sans-serif;
  /* font-family: 'Gowun Batang', serif; */
  /* font-family: 'DM Mono', monospace; */
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smooth: always;
  overflow-x: hidden;
}

#root {
  overflow: hidden;
}

button {
  /* font-family: 'Gowun Batang', serif; */
  /* font-family: 'DM Mono', monospace; */
  font-family: 'Poppins', sans-serif;
}

input {
  font-family: 'Poppins', sans-serif;

}

h1 {
  font-weight: 400;
  font-size: 2rem;
  color: #f5f5f5;
  font-family: 'Poppins', sans-serif;
  /* font-family: 'Gowun Batang', serif; */
}

h2 {
  font-weight: 400;
  font-size: 1.5rem;
  color: #f5f5f5;
  font-family: 'Poppins', sans-serif;
  /* font-family: 'Gowun Batang', serif; */
}

p {
  font-weight: 100;
  font-size: 1rem;
  color: #f5f5f5;
}

a {
  font-weight: 100;
  font-size: 1rem;
  color: #f27200;

  :hover {
    color: #f5f5f5;
  }

  transition: all ease 0.1s;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* This used to work for the parent element of button divs */
/* But it does not work with newer browsers, the below doesn't hide the play button parent div */

*::-webkit-media-controls-panel {
  display: none!important;
  -webkit-appearance: none;
}

/* Old shadow dom for play button */

*::-webkit-media-controls-play-button {
  display: none!important;
  -webkit-appearance: none;
}

/* New shadow dom for play button */
/* This one works! */

*::-webkit-media-controls-start-playback-button {
  display: none!important;
  -webkit-appearance: none;
}